<template>
  <section>
      <div class="container">
        <nav class="navbar navbar-expand-lg text-white">
            <router-link class="navbar-brand text-light" :to="{name:'home'}">Navbar</router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <!-- <span class="navbar-toggler-icon"></span> -->
                <i class="fas fa-th-large text-white"></i>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="navbar-nav mr-auto dropdown has-megamenu">
                    <!-- <input class="form-control mr-sm-2 search" type="text" placeholder="Search" aria-label="Search"> -->
                        <!-- <input class="form-control mr-sm-2" type="search" v-model="search" @keyup="surahSearch()" placeholder="Search" data-toggle="dropdown" aria-label="Search"> -->
                        <input class="form-control mr-sm-2 search" type="search" v-model="search" placeholder="Search" data-toggle="dropdown" aria-label="Search">
                        <div class="dropdown-menu search-menu" role="menu">
                            <h2 class="title">Hasil Pencarian</h2>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="col-megamenu category-list">
                                        <div class="search-results">
                                            <div class="card" v-for="(surah,index) in resultQuery" :key="index">
                                                <router-link :to="{name:'surah',params:{no:surah.number}}">
                                                    <div class="card-body">
                                                        {{surah.name.transliteration.id}} ({{surah.numberOfVerses}})
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- col-megamenu.// -->
                                </div>
                            </div>
                            <!-- end row -->
                        </div>
                </div>
                <div class="form-inline my-2 my-lg-0">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item active nav-list-item">
                            <router-link class="nav-link text-light" :to="{name:'quran-list'}">
                                Surah List <span class="sr-only">(current)</span>
                            </router-link>
                        </li>
                        <li class="nav-item nav-list-item">
                            <a class="nav-link text-light" href="#">Bookmark </a>
                        </li>
                        <li class="nav-item nav-list-item">
                            <a class="nav-link text-light" href="#">Donate </a>
                        </li>
                    </ul>
                    
                </div>
            </div>
        </nav>
      </div>
  </section>
</template>

<script>
// import $ from "jquery";
export default {
    data(){
        return{
            dataList:{},
            search:null
        }
    },
    mounted: function() {
        // $(document).on('click', '.dropdown-menu', function (e) {
        //     e.stopPropagation();
        // });
        this.getDataSurah()
    },
    methods:{
        getDataSurah(){
            this.axios.get(this.$url+'surah')
            .then(res => {
                this.dataList = res.data.data
                // console.log(res.data.data);
            }).catch(err => {
                console.log(err);
            })
        }
    },
    computed: {
        resultQuery(){
            if(this.search){
                return this.dataList.filter((item)=>{
                    return this.search.toLowerCase().split(' ').every(v => item.name.transliteration.id.toLowerCase().includes(v))
                })
            }else{
                return this.resources;
            }
        }
    }
}
</script>

<style>
@media all and (min-width: 992px) {
    .navbar{ padding-top: 0; padding-bottom: 0; }
    .navbar .has-megamenu{position:static!important;}
    .navbar .megamenu{left:0; right:0; width:100%; padding:20px;  }
    .navbar .mobile-megamenu{left:0; right:0; width:100%;}
    .navbar .search-menu{left:7vw; right:0; width:45vw; padding:20px;  }
    .navbar .nav-link{ padding-top:1rem; padding-bottom:1rem;  }
}
</style>