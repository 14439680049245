<template>
  <section class="home-page-wrapper" :class="{'home-height' : routeNow == 'home'}">
      <Navbar></Navbar>
      
      <router-view></router-view>
  </section>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
    data(){
        return{
            routeNow:null,
        }
    },
    watch: {
      $route: {
        immediate: true,
        handler() {
            this.routeNow = this.$route.name
        }
      }
    },
    components:{
        Navbar
    }
}
</script>

<style>
@import '../assets/css/style.css';
</style>